import React from 'react';
import {Order} from '../Order/orders';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import App from "../../App";
import {Printer} from "../Printer/printer";
import {PrinterInfo} from "../Printer/printerinfo";

function Main() {
    return (
        <div className={"container"}>
            <Router>
                <Routes>
                    <Route path="/order/:orderId" element={<Order/>}/>
                    <Route path="/printer/:printerId" element={<Printer/>}/>
                    <Route path="/printer/:printerId/:source" element={<Printer/>}/>
                    <Route path="/printerinfo/:printerId" element={<PrinterInfo/>}/>
                    <Route path="/" element={<App/>}/>
                </Routes>
            </Router>
        </div>
    )

}
export default Main

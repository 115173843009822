import React, {useEffect, useState} from "react"
import {MapContainer, TileLayer, Marker} from 'react-leaflet'


export default function MyMapInfo(props) {
    const [latitude, setLatitude] = useState(props.printer.latitude);
    const [longitude, setLongitude] = useState(props.printer.longitude);
    useEffect(() => {
        setLatitude(props.printer.latitude);
        setLongitude(props.printer.longitude);
    },[]);
    var coords = [latitude, longitude];
    // var coords = [51.113219, 17.034369];
    return (props.printer && props.printer.latitude !== 0?
        <>
            {/*<MapContainer center={[51.113219, 17.034369]} zoom={12} scrollWheelZoom={true} width={"100%"}>*/}
            <MapContainer center={coords} zoom={12} scrollWheelZoom={true} width={"100%"}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                    key={1}
                    position={[latitude, longitude]}>
                </Marker>

                {/*{printersData.map(pr => (*/}
                {/*    <Marker*/}
                {/*        key={pr.id}*/}
                {/*        position={[pr.gps.latitude, pr.gps.longitude]}>*/}
                {/*    </Marker>*/}
                {/*))}*/}
            </MapContainer>
        </>:<p>Loading...</p>
    )
}

import React from "react";
import {useParams} from "react-router-dom";
// import {FileUpload} from "../FileUpload/fileupload"

// class Order extends Component{
//     render(){
//         const { orderId} = useParams();
//         return(
//             <div>
//                 <h2>order: {orderId}</h2>
//             </div>
//         )
//     }
// }
// export default Order;
export function Order() {
    const {orderId} = useParams();

    return <div><h2>order: {orderId}</h2>
    {/*<FileUpload/>*/}
    </div>;
}
// class Payment{
//     amount = 0;
//
// }
// class File {
//     filename = "";
//     type = "";
// }

import './App.css';
// import MyMap from "./components/Maps/MyMap";
// import GoogleMaps from "./components/Maps/GoogleMaps";
// import GoogleMapsNew from "./components/Maps/GoogleMapsNew";
import GoogleMapsUp from "./components/Maps/GoogleMapsUp";
function App() {
  return (
    <div className="App">
        <GoogleMapsUp/>
    </div>
  );
}

export default App;

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';
import {Alert, Button, Card, CardContent, CardHeader, Grid, Paper} from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import {useParams} from "react-router-dom";
import MyMapInfo from '../Maps/MyMapInfo'
import {Link} from 'react-router-dom';


function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

const Item = styled(Paper)(({theme}) => ({}));
const goToOrder = () => {
    alert('kk');
}

export function PrinterInfo(props) {
    const {printerId} = useParams();

    const [printer, setPrinter] = React.useState(null);
    const [openhours, setOpenhours] = React.useState(null);
    const [orderurl, setOrderurl] = React.useState("");

    React.useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}/printerinfo/` + printerId;
        axios.get(url).then((response) => {
            // console.log(response.data.place.openinghours)
            setPrinter(response.data);
            setOpenhours(response.data.place.openinghours);
            setOrderurl("/printer/" + response.data.printerid + "/WEB")
        });
    }, [printerId]);
    // let url = `${process.env.REACT_APP_PRINTER_URL}/printer/${printer.id}`;
    return (
        <React.Fragment>
            <CssBaseline/>
            <ElevationScroll {...props}>
                <AppBar style={{ background: '#3A9EA3' }}>
                    <Toolbar>
                        <Typography variant="h6" component="div">
                            Iprigo
                        </Typography>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar/>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item width={"100%"}>
                            {printer && <MyMapInfo printer={printer}/>}
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item width={"100%"}>
                            {printer && printer.status === 'active'? <Button component={Link} to={orderurl} className={"btnprint"} size="large"
                                    endIcon={<SendIcon/>} variant="contained" color="error">Drukuj tutaj</Button>:<Alert severity="error">Drukarka nie jest w tej chwili dostępna</Alert>}
                        </Item>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                        <Card>
                            <CardHeader
                                title="Tu znajdziesz drukarkę:"
                                // subheader="Sprawdź"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[200]
                                            : theme.palette.grey[700],
                                }}
                            />
                            <CardContent>
                                {/*{printer ? printer.name : ""}*/}
                                <p className={"placetitle"}>{printer ? printer.place.name : ""}</p>
                                <p className={"placetitle"}>{printer ? printer.place.address + " " + printer.place.city : ""}</p>
                                <p>Godziny otwarcia:</p>

                                {/*{printer ? printer.map((prt) => prt.name) :""}*/}
                                <ul className={"openinghours"}>
                                    {openhours ? openhours.map(
                                        function (d, idx) {
                                            let godziny = d.openT + ' - ' + d.closeT
                                            if (d.openT === d.closeT) {
                                                godziny = 'Nieczynne'
                                            }
                                            return (<li key={idx}>{d.day}: {godziny} </li>)
                                        }
                                    ) : ""}
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} lg={6} md={6}>
                        <Card>
                            <CardHeader
                                title="Przydatne informacje:"
                                // subheader="Sprawdź"
                                sx={{
                                    backgroundColor: (theme) =>
                                        theme.palette.mode === 'light'
                                            ? theme.palette.grey[200]
                                            : theme.palette.grey[700],
                                }}
                            />
                            <CardContent>
                                {/*{printer ? printer.name : ""}*/}
                                <p>Adres email drukarki: <span
                                    className={"placetitle"}>{printer ? printer.email : ""}</span></p>
                                <p>Cena wydruku cz/b: <span
                                    className={"placetitle"}>{printer ? printer.priceperpagebw : ""} PLN</span> za
                                    stronę</p>
                                <p>Adres email drukarki: <span
                                    className={"placetitle"}>{printer ? printer.email : ""}</span></p>
                                <p>{printer ? printer.description : ""}</p>
                            </CardContent>
                        </Card>
                    </Grid>
                    {/*<Grid item xs={12} lg={6}>*/}
                    {/*    <Item>xs=4</Item>*/}
                    {/*</Grid>*/}
                </Grid>
            </Container>
        </React.Fragment>
    );
}

import React, {
    useState,
    useRef,
    useEffect
} from 'react';
import {
    useAutocompleteService,
    useGoogleMap,
    usePlacesService
} from '@ubilabs/google-maps-react-hooks';

import styles from './places-autocomplete-service.module.css';

const maxNumberOfSuggestions = 5;

const PlacesAutocompleteService = () => {
    const inputRef = useRef  (null);
    const timeout = useRef  (null);

    const [inputValue, setInputValue] = useState  ('');
    const [suggestions, setSuggestions] = useState ([]);
    const [suggestionsAreVisible, setSuggestionsAreVisible] =
    useState  (false);

    const map = useGoogleMap();
    const autocompleteService = useAutocompleteService();
    const placesService = usePlacesService();

    // Update the user input value
    const handleInputChange = (event) => {
        setInputValue(event.target.value);

        if (timeout.current) {
            clearTimeout(timeout.current);
        }

        // Show dropdown with a little delay
        timeout.current = setTimeout(() => {
            setSuggestionsAreVisible(true);
        }, 300);
    };

    // Handle suggestion selection
    const selectSuggestion = (
        suggestion
    ) => {
        inputRef.current?.focus();
        setInputValue(suggestion.label);

        // Close dropdown
        setSuggestionsAreVisible(false);

        // Get the location from Places Service of the selected place and zoom to it
        placesService?.getDetails(
            {placeId: suggestion.id},
            (
                placeResult,
                status
            ) => {
                if (
                    status !== window.google.maps.places.PlacesServiceStatus.OK ||
                    !placeResult
                ) {
                    return;
                }

                // Get position of the suggestion to move map
                const position = placeResult.geometry?.location;

                if (map && position) {
                    map.setZoom(14);
                    map.panTo(position);
                }
            }
        );
    };

    // Update suggestions and get autocomplete place suggestions
    useEffect(() => {
        if (inputValue.length >= 2) {
            autocompleteService?.getPlacePredictions(
                {
                    input: inputValue
                },
                (
                    predictions,
                    status
                ) => {
                    if (
                        status !== window.google.maps.places.PlacesServiceStatus.OK ||
                        !predictions
                    ) {
                        return;
                    }

                    const autocompleteSuggestions = predictions
                        .slice(0, maxNumberOfSuggestions)
                        .map(prediction => ({
                            id: prediction.place_id,
                            label: prediction.description
                        }));

                    // Update suggestions for dropdown suggestions list
                    setSuggestions(autocompleteSuggestions);
                }
            );
        } else {
            setSuggestions([]);
        }
    }, [inputValue]);

    return (
        <>
            {/*<label htmlFor="places-search-autocomplete">Search for a location:</label>*/}
            <input
                ref={inputRef}
                className={styles.searchInput}
                value={inputValue}
                onChange={handleInputChange}
                autoComplete="off"
                role="combobox"
                aria-autocomplete="list"
                aria-controls="search-suggestions"
                aria-expanded={suggestionsAreVisible}
                id="places-search-autocomplete"
                placeholder="Wyszukaj"
            />

            {suggestionsAreVisible && (
                <ul
                    className={styles.suggestions}
                    id="search-suggestions"
                    role="listbox"
                    aria-label="Suggested locations:">
                    {suggestions.map(suggestion => (
                        <li
                            key={suggestion.id}
                            onClick={() => selectSuggestion(suggestion)}
                            id={suggestion.id}
                            role="option">
                            <span>{suggestion.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default PlacesAutocompleteService;

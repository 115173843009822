import React from "react";
import {useParams} from "react-router-dom";
import axios from "axios"

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';
import {Alert, Button, Card, CardContent, CardHeader, Grid, Paper} from "@mui/material";
import styled from "styled-components";

function ElevationScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

export function Printer(props) {
    const {printerId} = useParams();
    const {source} = useParams();


    const [printer, setPrinter] = React.useState(null);

    React.useEffect(() => {
        let url = `${process.env.REACT_APP_API_URL}/printer/` + printerId + "/order/new"
        if (source)
            url += "/" + source;
        axios.get(url).then((response) => {
            // console.log(response.data);
            setPrinter(response.data);
        });
    }, []);
    let komunikat = 'Trwa tworzenie zamówienia...';
    let commclass = 'info';
    if (printer && printer.orderid != 'noprinter')
        window.location.replace(`${process.env.REACT_APP_ORDER_URL}/` + printer.orderid);
    else {
        komunikat = 'Wybrana drukarka jest w tej chwili niedostępna. Proszę spróbować później';
        commclass = 'error';
    }
    return (
        <React.Fragment>
            <CssBaseline/>
            <ElevationScroll {...props}>
                <AppBar style={{ background: '#3A9EA3' }}>
                    <Toolbar>
                        <Typography variant="h6" component="div">
                            Iprigo
                        </Typography>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar/>
            <Container>
                <Alert severity={commclass}>{komunikat}</Alert>
            </Container>
        </React.Fragment>
    );

}

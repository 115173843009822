import {useGoogleMap} from '@ubilabs/google-maps-react-hooks';
import {useEffect} from "react";

const mapOptions = {
    center: {lat: 53.5582447, lng: 9.647645},
    zoom: 6,
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
        position: 3 // Right top
    }
};
const Center = () => {
    const map = useGoogleMap();
    useEffect(() => {
        navigator.geolocation.getCurrentPosition((position) => {
            const mapOptions = {
                center: {lat: position.coords.latitude, lng: position.coords.longitude},
                zoom: 12,
                // disableDefaultUI: true,
                zoomControl: true,
                zoomControlOptions: {
                    position: 1 // Left top
                },
                mapTypeControlOptions: {
                    position: 10
                }

            };
            // console.log(position);
            if (map) {
                map.setOptions(mapOptions);
            }
            // console.log('Center');
            // console.log(map);
        });

    }, [map])
}
export default Center;

import React, {useState, useCallback, forwardRef, useEffect} from "react";
import {GoogleMapsProvider, useGoogleMap} from "@ubilabs/google-maps-react-hooks";
import {MarkerClusterer} from "@googlemaps/markerclusterer";
import SuperClusterAlgorithm from "../utils/superClusterAlgorithm";
// import printersData from '../MapData/printers.json'
import Prts from '../MapData/prts'
import {faPrint} from "@fortawesome/free-solid-svg-icons";

import PlacesAutocompleteService from "../places-autocomplete-service/places-autocomplete-service";
import MapCanvas from "../map-canvas/map-canvas";
import Center from "../Center/Center";
import axios from "axios";

export default function GoogleMapsUp() {
    const [mapContainer, setMapContainer] = useState(null);
    const [centerlat, setCenterlat] = useState(51.113219);
    const [centerlng, setCenterlng] = useState(17.034369);
    const [printers, setPrinters] = useState([]);
    const mapRef = useCallback(
        (node) => {
            node && setMapContainer(node);
        },
        []
    );
    const onLoad = useCallback((map) => addMarkers(map), []);

    useEffect(() => {
        // const url = `${process.env.REACT_APP_API_URL}/printers`;
        // // console.log(url);
        // axios.get(url).then((response) => {
        //     // console.log(response.data);
        //     setPrinters(response.data);
        // });
    }, [])

    const mapOptions = {
        // Add your map options here
        // `center` and `zoom` are required for every map to be displayed
        // center: {lat: 51.113219, lng: 17.034369},
        center: {lat: centerlat, lng: centerlng},
        zoom: 10,
        zoomControl: true,
        zoomControlOptions: {
            position: 1 // Left top
        },
        mapTypeControlOptions: {
            position: 10
        }
    };

    return (
        <div className="gmap" style={{height: '100vh', width: '100%'}}>
            <GoogleMapsProvider
                googleMapsAPIKey={process.env.REACT_APP_MAP_API_KEY}
                mapContainer={mapContainer}
                onLoadMap={onLoad}
                libraries={['places']}
                mapOptions={mapOptions}>

                <React.StrictMode>
                    <div id="container" style={{height: '100vh', width: '100%'}}>
                        <MapCanvas ref={mapRef}/>
                        <PlacesAutocompleteService/>
                        <Center/>
                    </div>
                </React.StrictMode>
            </GoogleMapsProvider>
        </div>
    );
}

function addMarkers(map){
    const url = `${process.env.REACT_APP_API_URL}/printers`
    axios.get(url).then((response) => {
        // console.log(response.data);
        const markers = [];
        response.data.forEach((printer)=>{
            markers.push(makeMarker(map,Number(printer.latitude),Number(printer.longitude), printer, printer.status == 'active'?"#000000":"#7F7F7F"));
        })
        new MarkerClusterer({
            markers,
            map,
            algorithm: new SuperClusterAlgorithm({radius: 200}),
        });
    });
}
function addMarkers1(map) {
    const markers = Prts.map(([name, lat, lng]) => {
        return makeMarker(map, lat, lng,name);
    });

    new MarkerClusterer({
        markers,
        map,
        algorithm: new SuperClusterAlgorithm({radius: 200}),
    });
}
function makeMarker(map, lat, lng, printer, color){
    const infoWindow = new window.google.maps.InfoWindow();
    const marker = new window.google.maps.Marker({

        position: {lat, lng},
        // icon: iconBase

        icon: {
            path: faPrint.icon[4],
            fillColor: color,
            fillOpacity: 1,
            anchor: new window.google.maps.Point(
                faPrint.icon[0] / 1, // width
                faPrint.icon[1] // height
            ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.075,
        },

    });

    marker.addListener("click", () => {
        let url = `${process.env.REACT_APP_PRINTER_URL}/printer/${printer.printerid}/WEB`;
        let urlprinter = `${process.env.REACT_APP_PRINTER_URL}/printerinfo/${printer.printerid}`;
        infoWindow.setPosition({lat, lng});
        let isprintable = "";
        if (printer.status == 'active')
            isprintable = `<div class="infolink"><a class="button" href=${url} target="_blank">Drukuj tutaj</a></div>`;
        // console.log(isprintable);
        infoWindow.setContent(`
                <div class="info-window">
                  <div class="infoname">${printer.name}</div>
                  <div class="infoaddress">
                  <span class="infoaddress address">${printer.address}</span>,
                  <span class="infoaddress city">${printer.city}</span>
                  <hr/>
                  </div>
                  ${isprintable}                  
                  <div class="infolink">
                  <a class="button" href=${urlprinter} target="_blank">Informacje</a>
                  </div>                  
                </div>
            `);
        infoWindow.open({map});
    });

    return marker;
}
